@import '../../theme/variables';

.item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 20px;

    .progressBar {
        height: 50px;
        width: 50px;
        padding-right: 0px;
        padding-left: 0px;
        margin: 0px;

        .bar {

            text {
                fill: #D9D9D9;
                font-size: 30px;
            }

            path {
                fill: #228B22;
            }

            circle {
                fill: $secondaryColor;
            }

            &.selected {
                text {
                    fill: $secondaryColor;
                }

                circle {
                    fill: #D9D9D9;
                }

                //Disabled for MVP
                // &:hover {
                //     circle {
                //         fill: $greyColor;
                //     }

                //     path {
                //         stroke: $greyColor;
                //     }
                //}
            }

            &.error {
                path {
                    stroke: $requiredColor;
                }
            }

            &:hover {
                //Disabled for MVP
                // cursor: pointer;

                // path {
                //     stroke: $secondaryColor;
                // }
            }
        }
    }

    .label {
        width: 200px;
        padding-left: 20px;
        padding-right: 10px;
        display: flex;
        align-content: center;
        align-items: center;
        color: #D9D9D9;
        font-size: 18px;
    }

    &:not(:last-child):after {
        content: '';
        margin-left: 25px;
        width: 2px;
        height: 40px;
        position: absolute;
        border-left: 2px dashed #fff;
        margin-top: 50px;
    }
}

$basePathColor: '#D9D9D9';
$backgroundColor: $secondaryColor;
$textColor: '#FFFFFF';
$textSize: '40px';

$alertPathColor: $requiredColor;
$inprogressPathColor: '#228B22';

$selectedBackgroundColor: '#D9D9D9';
$selectedTextColor: $secondaryColor;

@media screen and (max-height: 890px) {
    .item {
        padding: 10px;
        padding-left: 20px;

        .progressBar {
            height: 40px;
            width: 40px;

            .bar {
                text {
                    font-size: 36px;
                }
            }
        }

        .label {
            width: 200px;
            padding-left: 20px;
            padding-right: 10px;
            font-size: 18px;
        }

        &:not(:last-child):after {
            margin-left: 20px;
            height: 20px;
            margin-top: 40px;
        }
    }
}

// @media screen and (max-height: 840px) {
//     .item {
//         padding: 10px;

//         .progressBar {
//             height: 30px;
//             width: 30px;

//             .bar {
//                 text {
//                     font-size: 30px;
//                 }
//             }
//         }

//         .label {
//             width: 200px;
//             padding-left: 20px;
//             padding-right: 10px;
//             font-size: 16px;
//         }

//         &:not(:last-child):after {
//             margin-left: 14px;
//             height: 20px;
//             margin-top: 30px;
//         }
//     }
// }

@media screen and (max-height: 780px) {
    .item {
        padding: 10px;
        padding-left: 20px;

        .progressBar {
            height: 35px;
            width: 35px;

            .bar {
                text {
                    font-size: 40px;
                }
            }
        }

        .label {
            width: 200px;
            padding-left: 20px;
            padding-right: 10px;
            font-size: 18px;
        }

        &:not(:last-child):after {
            margin-left: 17px;
            height: 20px;
            margin-top: 35px;
        }
    }
}

@media screen and (max-height: 740px) {
    .item {
        padding: 10px;
        padding-left: 20px;

        .progressBar {
            height: 30px;
            width: 30px;

            .bar {
                text {
                    font-size: 30px;
                }
            }
        }

        .label {
            width: 200px;
            padding-left: 20px;
            padding-right: 10px;
            font-size: 16px;
        }

        &:not(:last-child):after {
            margin-left: 14px;
            height: 20px;
            margin-top: 30px;
        }
    }
}

@import '../../theme/variables';

.main {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $backgroundColor;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: left;
        background: $headerColor;
        color: $whiteColor;
        font-size: 24px;
        line-height: 29px;
        width: 100%;

        .title {
            padding-left: 30px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 35px;
        height: 100%;
    }

    .contentEmpty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        text-align: center;
        color: $secondaryColor;
        background-color: $whiteColor;

        .image {
            width: 100px;
            height: 100px;
            padding-bottom: 30px;
        }

        .text {
            padding: 20px;
            font-size: 24px;
        }
    }

    .message {
        text-align: center;
        color: $secondaryColor;
        background-color: $whiteColor;
        padding: 20px;

        .text {
            padding:20px;
            font-size: 20px;
        }
    }

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 20px 25px 90px;

        .buttonSection {
            display: flex;
            flex-direction: row;
        }

        &.empty {
            background-color: $whiteColor;
        }
    }
}

.info {
    position: relative;
    top: 5px;
    left: 10px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

@media screen and (max-height: 876px) {
    .main {
        .footer {
            padding: 0 50px 90px;
        }
    }
}
@import '../../theme/_variables.scss';

.button {
    font-size: 16px;
    width: 150px;
    height: 40px;
    border: none;
    font-weight: bold;
    padding: 10px;
    margin: 5px;

    &:hover {
        background-color: $hoverColor;
        cursor: pointer;
    }

    &.primary {
        background-color: $primaryColor;
        color: $secondaryColor;

        &:hover {
            background-color: #48CBBB;
        }
    }

    &.secondary {
        background-color: $secondaryColor;
        color: $whiteColor;
    }


    &.disabled {
        background-color: $greyColor;
        color: $whiteColor;
        cursor: default;

        &:hover {
            background-color: $greyColor;
        }
    }
}

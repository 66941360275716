@import '../../componentsLibrary/Accordion/Accordion.module.scss';
@import '../../theme/Info.module.scss';
@import '../../theme/variables';

.beneficialOwner {
    width: 100%;

    .title {
        font-size: 24px;
        line-height: 29px;
    }

    .addButton {
        height: 40px;
        font-size: 18px;
        margin: 0 25px 0 0;
        position: relative;
        top: -56px;
        align-self: flex-end;
        padding-left: 30px;
        padding-right: 30px;
        min-width: fit-content;
        
        &:before {
            content: url('../../assets/img/plusSign.png');
            position: relative;
            display: inline-flex;
            top: -3px;
            right: 5px;
        }

        &.disabled {
            &:before {
                content: url('../../assets/img/plusSign_white.png');
            }
        }

        .buttonLabel {
            position: relative;
            top: -9px;
            font-size: 16px;
        }
    }

    .switchBoxWrapper {
        align-self: end;
        position: relative;
        top: -4px;
        padding-top: 10px;

        >p {
            position: relative;
            top: 3px;
            padding: 25px;
            vertical-align: middle;
            color: $contourColor;
        }
    }

    .label {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $contourColor;
        position: relative;
        padding-top: 12px;
        align-self: flex-start;
        padding-left: 55px;
        width: 75%;
        display: inline-flex;
    }

    .inline {
        display: inline;
        position: relative;
        padding-top: 15px;
    }

    input {
        &:checked {
            + {
                .slider {
                    background-color: $secondaryColor;
                }
            }
        }
    }

    .beneficialOwnerForm {
        background: $whiteColor;
    }

    .row {
        top: 2px;
        position: relative;
        display: inline-flex;
        width: 97%;
        background-color: #E6E6E6;
        padding: 15px 0;
        margin: 25px;
    }
}

@media screen and (max-width: 1542px) {
    .beneficialOwner {
        .switchBoxWrapper {
            margin: 5px 0 0 30px;
        }

        .row {
            width: 95% ;
            display: block;
        }
    }
}
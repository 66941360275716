@import '../../theme/variables';
@import '../../theme/Info.module.scss';
@import '../../theme/Error.module.scss';
@import '../../theme/Required.module.scss';

.inputWrapper {
    padding: 5px;
    color: $contourColor;

    .label {
        padding: 10px 9px;
        margin-left: 5px;
        margin-right: 5px;
        font-weight: 400;
        font-size: 16px;
        display: inline-flex;
        width: 225px;
        position: relative;
        top: 3px;
    }

    .selectWrapper {
        overflow: hidden;
        width: 355px;
        height: 40px;
        background: $whiteColor;
        border: 1px solid $contourColor;
        border-radius: 2px;
        margin-left: 15px;
        display: inline-flex;
        vertical-align: middle;

        &:after {
            position: absolute;
            top: 0;
            right: 15px;
            z-index: 1;
            color: #bbbbbb;
            line-height: 40px;
            font-size: 25px;

            .ie & {
                display: none;
            }
        }

        &.error {
            border: 1px solid $requiredColor;
            padding: 0;
        }

        .select {
            font-weight: 400;
            font-size: 16px;
            position: relative;
            display: block;
            width: 100%;
            outline: none;
            color: $contourColor;
            cursor: pointer;
            border: none;
            padding: 0 10px;
            font-family: inherit;
            background-color: $whiteColor;
            appearance: none;
            background-repeat: no-repeat;
            background-position: right;
            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10px' width='85px'><text x='60' y='2' fill='gray' font-size='16' font-family='Consolas' font-weight='bold' rotate='90'>></text></svg>");

            .option {
                font-size: 16px;
            }

            .option[disabled] {
                color: $disabledColor;
                border: none;
                cursor: default;
            }

            &.disabled {
                color: $disabledColor;
                cursor: default;

               
            }
        }

        .select:-moz-focusring {
            color: transparent;
        }

        .select::-ms-expand {
            display: none;
        }
    }
}


@media screen and (max-height: 720px),
screen and (max-width: 1280px) {
	.inputWrapper {
		.label {
			width: 320px;
		}
	}
}

@media screen and (max-width: 1674px) {
    .inputWrapper {
        .label {
            width: 350px;
        }
    }
}
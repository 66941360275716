@import '../../theme/variables';

.main {
    background-color: $accentColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding-top: 150px;
    font-size: 20px;
    color: $whiteColor;

    .title {
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 48px;
        text-align: center;
        padding: 20px;
    }

    .image {
        height: 190px;
        width: 190px;
        padding: 20px;
    }

    .text {
        display: flex;
        flex-direction: column;
        width: 60%;

        .description {
            text-align: justify;
            text-justify: inter-word;
        }


        .dataProcessingAgreement {
            text-align: left;

            a {
                color: $whiteColor;

                &:hover {
                    color: $greyColor;
                }
            }
        }

        .checkboxSection {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            .termsAndConditions {
                text-align: left;

                a {
                    color: $whiteColor;

                    &:hover {
                        color: $greyColor;
                    }
                }
            }
        }

        .landingPageButton {
            margin: 20px;
            align-self: center;
        }
    }

    .solarisLogo {
        height: 80px;
        width: 150px;
        position: absolute;
        right: 10px;
        bottom: 0;
    }

    .padding{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 125px;

        .resumeButtonPadding{
            padding-top: 40px;
        }
    }
}

@media screen and (max-height: 876px) {
    .main {
        padding: 40px;

        .image {
            height: 160px;
            width: 160px;
            padding: 0;
        }

        .text {
            width: 65%;
        }
    }
}
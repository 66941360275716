.main {
    display: flex;
    flex-direction: row;
    height: 80%;
    top: 70px;
    position: relative;
    height: 100%;
}


@media screen and (max-height: 892px), screen and (max-width: 1250px) {
    .main {
        top: 60px;
    }
}

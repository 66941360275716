@import '../../theme/_variables.scss';

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 32px;

    .input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $contourColor;
        -webkit-transition: .4s;
        transition: .4s;

        &:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 3px;
            background-color: $whiteColor;
            -webkit-transition: .4s;
            transition: .4s;
        }

        &.round {
            border-radius: 20px;

            &:before {
                border-radius: 50%;
            }
        }

        &.disabled {
            cursor: default;
            background-color: $disabledColor !important;
        }
    }
}

.input {
    &:checked {
        + {
            .slider {
                background-color: $primaryColor;

                &:before {
                    -webkit-transform: translateX(26px);
                    -ms-transform: translateX(26px);
                    transform: translateX(26px);
                }
            }
        }
    }

    &:focus {
        + {
            .slider {
                box-shadow: 0 0 1px #2196F3;
            }
        }
    }
}

@media screen and (max-height: 892px),
screen and (max-width: 1250px) {
    .switch {
        width: 55px;
    }

    .slider {
        &:before {
            height: 20px;
            width: 20px;
            left: 5px;
            bottom: 3px;
        }
    }
}

@media screen and (max-width: 1550px) {
    .switch {
        width: 55px;
        height: 28px;

        .slider {
            &:before {
                height: 20px;
                width: 20px;
                bottom: 4px;
            }
        }
    }
}
@import '../../theme/variables';
@import '../../theme/Info.module.scss';
@import '../../theme/Error.module.scss';
@import '../../theme/Required.module.scss';

.inputWrapper {
    padding: 5px;
    color: $contourColor;

    .label {
        padding: 10px 14px;
        width: 225px;
        font-weight: 400;
        font-size: 16px;
        display: inline-block;
    }

    .selectWrapper {
        margin-left: 15px;
        display: inline-flex;
        font-family: inherit;
        padding: 0;
    }
}

@media screen and (max-width: 1674px) {
    .inputWrapper {
        .label {
            width: 300px;
        }
    }
}
@import '../../theme/variables';
@import '../../theme/Info.module.scss';
@import '../../theme/Error.module.scss';
@import '../../theme/Required.module.scss';

.inputWrapper {
	padding: 5px;
	color: $contourColor;
	z-index: 6;

	label {
		padding: 9px 14px 12px 14px;
        width: 225px;
        font-weight: 400;
        font-size: 16px;
        display: inline-block;
	}

	.reactDatePicker {
		border: 1px solid $contourColor;
		width: 340px;
		height: 40px;
		background: $whiteColor;
		padding: 0 15px 0 0;
		box-sizing: unset;
		margin-left: 15px;

		& * {
			font-weight: 400;
			font-size: 16px;
			font-family: 'BatonTurbo';
			text-align: center;
			border: none;
			color: $contourColor;
			border-radius: 2px;
			svg {
				stroke: $contourColor; 
			}
		}

		&,
		& *:focus-visible {
			outline: none;
		}

		& *:invalid {
			background: none;
		}

		&.error {
			border: 1px solid red;
		}

		input:disabled {
			color: $disabledColor;
			cursor: default;
		}

		button:disabled {
			svg {
				stroke: $disabledColor; 
			}
			cursor: default;
		}

	}

	
	.disabled {
		& * {
			color: $disabledColor !important;
		}
		color: $disabledColor !important;
	}

	.reactCalendar {
		border: 1px solid $contourColor;
		margin-left: 2px;
	}
}

.error {
    display: inline-block;
}

@media screen and (max-height: 720px),
screen and (max-width: 1280px) {
	.inputWrapper {
		label {
			width: 298px;
		}
	}
}

@media screen and (max-width: 1674px) {
    .inputWrapper {
        label {
            width: 360px;
        }
    }
}
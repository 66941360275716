@import '../../theme/_variables.scss';

.header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 15px;
    background-color: $secondaryColor;
    height: 40px;
    line-height: 40px;
    position: fixed;
    width: 100%;
    z-index: 1000;

    .image {
        height: 40px;
    }

    .account {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-right: 40px;


        .title {
            font-size: 18px;
            color: #FFFFFF;
            font-weight: bold;
            border-right: 1px solid #FFFFFF;
            padding-right: 35px;
            margin-right: 35px;
        }

        .accountMenuContainer {
            width: 40px;

            .accountMenu {
                width: 40px;
                height: 40px;
                border: none;
                background-color: $accentColor;
                border-radius: 50%;
                cursor: pointer;
                text-align: center;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: #FFFFFF;
                box-shadow: 0 0 1px white;
            }

            .menu {
                background-color: $accentColor;
                list-style-type: none;
                border: solid 1px #332B78;
                box-shadow: 0 0 1px white;
                padding: 0px;
                width: 55px;
                border-radius: 5px;
                position: absolute;
                right: 130px;

                .menuItem {



                    button {
                        color: white;
                        background-color: $accentColor;
                        border: none;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-height: 892px),
screen and (max-width: 1250px) {
    .header {
        padding: 10px;

        .image {
            height: 35px;
        }

        .account {
            .accountMenu {
                top: 5px;
            }
        }

    }
}
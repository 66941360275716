.main {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: left;
        background: #2D266C;
        color: #FFFFFF;
        font-size: 24px;
        line-height: 29px;
        width: 100%;

        .title {
            padding-left: 30px;
        }
    }
}

.info {
    position: relative;
    top: 5px;
    left: 10px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}
@import '../../theme/variables';
@import '../../theme/Info.module.scss';
@import '../../theme/Error.module.scss';
@import '../../theme/Required.module.scss';

.inputWrapper {
    padding: 5px;
    color: $contourColor;

    label {
        padding: 9px 14px;
        display: inline-block;
        width: 225px;
        font-weight: 400;
        font-size: 16px;
    }

    .input {
        width: 325px;
        height: 41px;
        background: $whiteColor;
        border: 1px solid $contourColor;
        border-radius: 2px;
        padding: 0 15px;
        display: inline-flex;
        font-weight: 400;
        font-size: 16px;
        color: $contourColor;
        margin-left: 15px;
        vertical-align: middle;
        font-family: inherit;

        &:focus-visible {
            outline: none;
        }

        &:disabled {
            color: $disabledColor;
            cursor: default;
        }

        input[type='tel'] {
            border: none;
            color: $contourColor;
            font-family: inherit;
            font-size: 16px;
            padding-left: 5px;
            height: 35px;
            z-index: 5;

            &:focus-visible {
                outline: none;
            }

            &:disabled{
                color: $disabledColor;
                background-color: $whiteColor;
            }
        }

        select {
            width: 322px;
            font-weight: 400;
            font-size: 16px;
            color: $contourColor;
            cursor: pointer;
            border: none;
            font-family: inherit;
            background-color: $whiteColor;
        }

        &.disabled {
            color: $disabledColor;
            select {
                cursor: default;
            }
        }
    }

    .error {
        border: 1px solid red;
    }
}

@media screen and (max-width: 1674px) {
    .inputWrapper {
        .label {
            width: 350px;
        }
    }
}
@import '../../theme/_variables.scss';

.container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .checkbox {
        &:checked {
            accent-color: $primaryColor;
        }

        &:disabled{
            color: $greyColor;
            border-color: $greyColor;
        }
    }

    .required {
        color: $requiredColor;
        padding-left: 10px;
    }
}

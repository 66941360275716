/* Units */
$bu: 12px;

/* Colors */
$primaryColor:  #50E2D0;
$secondaryColor: #332B78;
$accentColor: #211C4E;
$greyColor: #b8b8b8;
$whiteColor: #ffffff;
$requiredColor: #FF0000;
$hoverColor: #3eb0a3;
$backgroundColor: #E6E6E6;
$headerColor: #2D266C;
$disabledColor: #d8d8d8;
$contourColor: #96989C;
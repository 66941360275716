@import '../../componentsLibrary/Accordion/Accordion.module.scss';
@import '../../theme/Info.module.scss';
@import '../../theme/variables';

.legalRepresentative {
    width: 100%;

    .title {
        font-size: 24px;
        line-height: 29px;
    }

    .addButton {
        height: 40px;
        font-size: 18px;
        margin: 0 25px 0 0;
        position: relative;
        top: -56px;
        align-self: flex-end;
        padding-left: 30px;
        padding-right: 30px;
        min-width: fit-content;
        
        &:before {
            content: url('../../assets/img/plusSign.png');
            position: relative;
            display: inline-flex;
            top: -3px;
            right: 5px; 
        }

        &.disabled {
            &:before {
                content: url('../../assets/img/plusSign_white.png');
            }
        }

        .buttonLabel {
            position: relative;
            top: -9px;
            font-size: 16px;
        }
    }

    .legalRepresentativeForm {
        background: $whiteColor !important;
    }
}
@import '../../theme/_variables.scss';

.main {
    position: fixed;
    inset: 0px;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    
    &.overlay {
        background-color: #211C4E;
    }

    &.hidden {
        display: none;
    }
}

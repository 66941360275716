@import '../../theme/variables';
@import '../../theme/Info.module.scss';
@import '../../theme/Error.module.scss';
@import '../../theme/Required.module.scss';

.inputWrapper {
    padding: 5px;
    color: $contourColor;

    .label {
        padding: 10px 14px;
        display: inline-flex;
        width: 225px;
        font-weight: 400;
        font-size: 16px;
    }

    .textarea {
        resize: none;
        width: 325px;
        height: 113px;
        display: inline-flex;
        border: 1px solid $contourColor;
        border-radius: 2px;
        margin-left: 15px;
        padding: 15px;
        padding-bottom: 13px;
        font-weight: 400;
        font-size: 16px;
        background: $whiteColor;
        color: $contourColor;
        font-family: inherit;
        vertical-align: top;

        &:focus-visible {
            outline: none;
        }

        &:disabled {
            color: $disabledColor;
            cursor: default;
        }

        &.error {
            border: 1px solid red;
        }
    }
}

@media screen and (max-width: 1659px) {
    .inputWrapper {
        .textarea {
            height: 137px;
        }
    }
}

@media screen and (max-height: 720px) {
    .inputWrapper {
        .textarea {
            height: 193px;
        }
    }
}

@media screen and (max-width: 1674px) {
    .inputWrapper {
        .label {
            width: 350px;
        }
    }
}
button {
    height: 40px;
}

.dropdown {
    svg {
        border-radius: 50%;
        height: 35px;
        width: 35px;
        border-color: grey;
        border-style: solid;
        border-width: 1px;
        &:hover {
            filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
        }
    }

    button {
        border: none;
    }

    ul {
        background: transparent;
        border:none;
        overflow: hidden;
        li {
            &:hover {
                background: transparent;
            }
        }
    }
}
.error {
    color: red;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 0 0 5px 332px;
    align-self: normal;
}

@media screen and (max-height: 876px) {
    .error {
        padding: 0 0 5px 20px;
    }
}
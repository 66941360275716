@import '../../theme/variables';
@import '../../theme/Info.module.scss';
@import '../../theme/Error.module.scss';
@import '../../theme/Required.module.scss';

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search); 
    @return if($index, 
        str-slice($string, 1, $index - 1) + $replace + 
        str-replace(str-slice($string, $index + 
        str-length($search)), $search, $replace), 
        $string); 
}         

@function hexToUrl($color) {
  $newcolor: str-replace($color, '#', '%23');
  @return $newcolor;
}


@mixin element-color-euro($color) {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='16px' width='85px'><text x='60' y='15' fill='#{hexToUrl($color)}' font-size='20' font-family='BatonTurbo'>€</text></svg>");
}

@mixin element-color-percentage($color) {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" height="16px" width="85px"><text x="60" y="15" fill="#{hexToUrl($color)}" font-size="20" font-family="BatonTurbo">%</text></svg>');
}

.inputWrapper {
    padding: 5px;
    color: $contourColor;

    .label {
        padding: 10px 14px;
        display: inline-block;
        width: 225px;
        font-weight: 400;
        font-size: 16px;
    }

    .input {
        width: 300px;
        height: 40px;
        background: $whiteColor;
        border: 1px solid $contourColor;
        border-radius: 2px;
        padding: 0 40px 0 15px;
        display: inline-flex;
        font-weight: 400;
        font-size: 16px;
        color: $contourColor;
        margin-left: 15px;
        vertical-align: middle;
        font-family: inherit;

        &:focus-visible {
            outline: none;
        }

        &.error {
            border: 1px solid $requiredColor;
        }

        &:disabled {
            color: $disabledColor;
            cursor: default;
        }

        &.symbols {
            background-repeat: no-repeat;
            background-position: right;
            margin-left: 15px;
    
            &.euroSymbol {
                @include element-color-euro("#{$contourColor}");

                &:disabled {
                   @include element-color-euro("#{$disabledColor}");
                }
        
            }
    
            &.percentageSymbol {
                @include element-color-percentage("#{$contourColor}");
    
                &:disabled {
                    @include element-color-percentage("#{$disabledColor}");
                }
            }
        }
       
    }

    

}

@media screen and (max-height: 720px),
screen and (max-width: 1280px) {
	.inputWrapper {
		.label {
			width: 340px;
		}
	}
}

@media screen and (max-width: 1674px) {
    .inputWrapper {
        .label {
            width: 350px;
        }
    }
}
@font-face {
  font-family: 'BatonTurbo';
  src: url('../assets/fonts/BatonTurboWeb-Regular.woff') format('woff'),
    url('../assets/fonts/BatonTurboWeb-RegularItalic.woff') format('woff'),
    url('../assets/fonts/BatonTurboWeb-Medium.woff') format('woff'),
    url('../assets/fonts/BatonTurboWeb-MediumItalic.woff') format('woff'),
    url('../assets/fonts/BatonTurboWeb-Book.woff') format('woff'),
    url('../assets/fonts/BatonTurboWeb-BookItalic.woff') format('woff'),
    url('../assets/fonts/BatonTurboWeb-Bold.woff') format('woff'),
    url('../assets/fonts/BatonTurboWeb-BoldItalic.woff') format('woff'),
    url('../assets/fonts/BatonTurboWeb-Heavy.woff') format('woff'),
    url('../assets/fonts/BatonTurboWeb-HeavyItalic.woff') format('woff'),
    url('../assets/fonts/BatonTurboWeb-Regular.eot') format('eot'),
    url('../assets/fonts/BatonTurboWeb-RegularItalic.eot') format('eot'),
    url('../assets/fonts/BatonTurboWeb-Medium.eot') format('eot'),
    url('../assets/fonts/BatonTurboWeb-MediumItalic.eot') format('eot'),
    url('../assets/fonts/BatonTurboWeb-Book.eot') format('eot'),
    url('../assets/fonts/BatonTurboWeb-BookItalic.eot') format('eot'),
    url('../assets/fonts/BatonTurboWeb-Bold.eot') format('eot'),
    url('../assets/fonts/BatonTurboWeb-BoldItalic.eot') format('eot'),
    url('../assets/fonts/BatonTurboWeb-Heavy.eot') format('eot'),
    url('../assets/fonts/BatonTurboWeb-HeavyItalic.eot') format('eot');
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  
  font-family: 'BatonTurbo', -apple-system, BlinkMacSystemFont, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

form, input, textarea, button {
  font-family: inherit
}

#root {
  height: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  border: 1px solid #B8B8B8;
}

/* Track */
::-webkit-scrollbar-track {
  width: 3px;
  background: #D9D9D9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  width: 3px;
  background: #B8B8B8;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #B8B8B8;
}

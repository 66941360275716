@import '../../theme/_variables.scss';

.section {
    padding: 10px 25px;

    .title {
        font-weight: 400;
        font-size: 16px;
        color: $secondaryColor;
        text-align: start;
        padding: 0;
        text-transform: uppercase;
    }

    .details {
        background: #FFFFFF;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        padding: 15px;
        border-radius: 5px;
    }
}

@import '../../../theme/variables';

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: center;
    color: $secondaryColor;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: left;
        background: $headerColor;
        color: $whiteColor;
        font-size: 24px;
        line-height: 29px;
        width: 100%;

        .title {
            padding-left: 30px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 35px;
        height: 100%;
        justify-content: center;
        width: 100%;
        text-align: center;

        .image {
            width: 100px;
            height: 100px;
            padding-bottom: 30px;
        }
    
        .title {
            font-size: 26px;
        }
    
        .description {
            padding:20px;
            font-size: 24px;

        }
    }


    .footer {
        display: flex;
        align-self: flex-end;
        flex-direction: row;
        justify-content: flex-end;
        margin: 20px 25px 90px;

        .buttonSection {
            display: flex;
            flex-direction: row;
        }
    }
}

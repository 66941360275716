@import '../../../theme/variables';

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: center;
    color: $secondaryColor;

    
    .header {
        display: flex;
        flex-direction: row;
        justify-content: left;
        background: $headerColor;
        color: $whiteColor;
        font-size: 24px;
        line-height: 29px;
        width: 100%;

        .title {
            padding-left: 30px;
        }
    }

    .content {
        .image {
            width: 100px;
            height: 100px;
            padding-bottom: 30px;
        }
    
        .description {
            font-size: 26px;
            text-align: center;
            padding-bottom: 30px;
        }
    }

    .footer {
        display: flex;
        align-self: flex-end;
        flex-direction: row;
        justify-content: flex-end;
        padding: 20px 25px 90px;

        .buttonSection {
            display: flex;
            flex-direction: row;
        }
    }
}

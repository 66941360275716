@import '../../componentsLibrary/Accordion/AccordionItem.module.scss';

.beneficialOwnerItem {
    width: 100%;

    .left {
        align-items: start;
    }

    .info {
        position: relative;
        top: 5px;
        left: 10px;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
}

.error {
    padding: 0 0 5px 307px;
}

@media screen and (max-height: 876px) {
    .error {
        padding: 0 0 5px 20px;
    }
}
//component: https://www.npmjs.com/package/react-tooltip; https://react-tooltip.com/
//styling: https://react-tooltip.com/docs/examples/styling

@import '../../theme/_variables.scss';

//If this container is not present, the order of precedence is not respected, so !important should be added to all lines
.container {
    z-index: 100;

    .tooltip {
        background-color: $whiteColor;
        border: 1px solid $backgroundColor; 
        border-radius: 10px;
        color: $greyColor;

        .arrow{
            border: 1px solid $backgroundColor; 
            border-right: none;
            border-top:none;
        }
    }
}
@import '../../theme/_variables.scss';

.container {
    width: 330px;
    background-color: $accentColor;
    height: 100%;

    .stepDescription {
        margin-left: 20px;
        margin-top: 50px;
        margin-right: 20px;
        overflow: hidden;
        text-align: justify;

        h2 {
            color: white;
            font-size: 28px;
            margin: 0;
        }

        p {
            font-size: 12px;
            font-weight: lighter;
            color: whitesmoke;
        }
    }

    .menu {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
    }

    .solarisLogo {
        height: 80px;
        width: 150px;
        position: absolute;
        left: 170px;
        bottom: 65px;
    }
}

@media screen and (max-height: 892px),
screen and (max-width: 1250px) {
    .container {
        .stepDescription {
            margin-top: 25px;
        }
    }
}

@media screen and (max-height: 892px){
    .container {
        .solarisLogo {
            height: 70px;
            width: 140px;
        }
    }
}

@media screen and (max-height: 780px) {
    .container {
        .solarisLogo {
            height: 60px;
            width: 120px;
        }

        .menu{
            height: 250px;
        }
    }
}

@media screen and (max-height: 740px) {
    .container {
        .menu{
            height: 220px;
        }
    }
}
@import "../../theme/variables";
@import '../../theme/Error.module.scss';

vaadin-upload {
  --lumo-icons-cross: url('../../assets/img/trash.svg');
}

.main {
  display: flex;
  flex-direction: row;
  background-color: $whiteColor;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 25px;
  border-radius: 5px;
  height: 100px;

  .description {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 30%;

    .icon {
      width: 30px;
      height: 30px;
      padding: 5px;
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 5px;

      .title {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        color: $secondaryColor;
        font-size: 16px;
        font-weight: bold;

        .required {
          color: $requiredColor;
          padding-left: 5px;
        }
      }

      .subTitle {
        color: $greyColor;
        font-size: 12px;
      }
    }
  }

  .uploadContainer {
    background-color: $whiteColor;
    width: 100%;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .fileName {
      color: $greyColor;
      padding-right: 10px;
    }

    .removeIcon {
      height: 30px;
      padding: 5px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.error {
  right: 10px;
  align-self: flex-end;
  padding: 0;
  position: absolute;
}
@import '../../theme/_variables.scss';

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: $accentColor;
    height: 100%;

    .title {
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        text-align: center;
        color: #FFFFFF;
        padding-top: 50px;
    }

    .layout {
        display: block;
        position: relative;
        padding-top: 10px;
        width: 70%;
        background-color: #2D266C;
        border: none;

        .row {
            color: #FFFFFF;
            font-style: normal;
            font-weight: 400;
            font-size: 26px;
            text-align: justify;

            .question {
                padding: 0 50px 0 50px;
                width: 80%;

                >p {
                    margin: 20px;
                }
            }

            .answer {
                width: 80%;

                >p {
                    padding: 0 20px 0 20px;
                }

                .switchButton {
                    width: 100px;
                    padding: 0 10px 10px 0;
                }
            }

            ul {
                position: relative;
                top: -10px;
                padding-left: 35px;
                font-weight: 200;
                font-size: 16px;
            }
        }

        .inline {
            position: relative;
            display: inline;
        }
    }

    .solarisLogo {
        height: 80px;
        width: 150px;
        position: absolute;
        right: 10px;
        bottom: 0;
    }
}

.info {
    position: relative;
    top: 4px;
    left: 10px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

@media screen and (max-height: 996px){
    .main {
        .title {
            font-size: 26px;
        }

        .layout {
            top: 0;
            width: 80%;

            .row {
                font-size: 24px;
                line-height: 24px;

                ul {
                    font-size: 16px;
                }
            }
        }
    }
  
    .info {
        top: 3px;
        width: 18px;
        height: 18px;
    }

    .button{
        position: relative;
        top: -20px;
    }
}

@media screen and (max-height: 876px){
    .main {
        .title {
            padding-top: 60px;
            font-size: 24px;
        }

        .layout {
            top: 0;
            width: 80%;

            .row {
                font-size: 18px;
                line-height: 18px;

                .question {
                    padding: 0 10px 0 50px;
                    width: 80%;
                }

                .answer {
                    width: 20%;

                    >p {
                        padding: 0 10px 0 20px;
                    }

                    .switchButton {
                        width: 100px;
                        padding: 0;
                    }
                }

                ul {
                    font-size: 14px;
                }

                td {
                    padding-top: 5px;
                    padding-bottom: 5px;
                    padding-right: 20px;
                }
            }

            .inline {
                top: 2px;
            }
        }

        .solarisLogo {
            bottom: 25px;
        }
    }
  
    .info {
        top: 3px;
        width: 18px;
        height: 18px;
    }

    .button{
        position: relative;
        top: -20px;
    }
}
@media screen and (max-height: 660px){
    .main {
        .solarisLogo {
            bottom: 0px;
        }
    }
  
    .button{
        position: relative;
        top: 0px;
    }
}

@import '../../theme/variables';

.main {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: left;
        background: #2D266C;
        color: #FFFFFF;
        font-size: 24px;
        line-height: 29px;
        width: 100%;

        .title {
            padding-left: 30px;
        }
    }

    .content {
        height: 100%;
        padding: 0px 50px;

        iframe {
            border: 0px;
            height: 100%;
            width: 100%;
        }
    }

    .pending {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        text-align: center;
        color: $secondaryColor;
    
        .text {
            padding:20px;
            padding-top: 30px;
            font-size: 24px;
        }
    }

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px 25px 90px;

        &.pending {
            justify-content: flex-end;
            align-self: flex-end;
        }

        .checkboxContainer {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
        }

        .buttonSection {
            display: flex;
            flex-direction: row;
        }
    }
}

@import '../../componentsLibrary/Accordion/AccordionItem.module.scss';
@import '../../theme/Info.module.scss';
@import '../../theme/_variables.scss';

.legalRepresentativeItem {
    width: 100%;

    .label {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $contourColor;
        position: relative;
        padding-top: 12px;
        align-self: flex-start;
        padding-left: 55px;
        width: 50%;
        display: inline-flex;
    }

    .switchBoxWrapper {
        align-self: end;
        position: relative;
        top: -4px;
        padding-top: 10px;

        >p {
            position: relative;
            top: 3px;
            padding: 25px;
            vertical-align: middle;
            color: $contourColor;
        }
    }

    .inline {
        display: inline;
        position: relative;
        padding-top: 15px;
    }

    .input {
        &:checked {
            + {
                .slider {
                    background-color: $secondaryColor;
                }
            }
        }
    }

    .row {
        position: relative;
        display: inline-flex;
        width: 100%;
    }

    .left {
        align-items: start;
    }
}
    
.error {
    padding: 0 0 5px 307px;
}

@media screen and (max-width: 1542px) {
    .legalRepresentativeItem {

        .switchBoxWrapper {
            margin: 5px 0 0 30px !important;
        }

        .row {
            width: 100% !important;
            display: block !important;
        }
    }
}

@media screen and (max-height: 876px) {
    .error {
        padding: 0 0 5px 20px;
    }
}
@import '../../theme/variables';

.content {
    position: relative;
    top: 50%;
    left: 50%;
    right: 50%;
    bottom: 50%;
    margin-right: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 15px;
    font-family: inherit;
    
    &:focus {
        outline: none;
    }
    
    &:focus-visible {
        outline: none;
    }
}

.modalContent {
    border: none;
    color: $contourColor;

    ul {
        line-height: 1.5em;
        font-size: 18px;
        text-align: justify;
    }
}

.button {
    width: 160px;
    height: 40px;
    font-size: 18px;
    border-radius: 5px;
    font-weight: 600;
    margin-top: -50px;
    margin-bottom: 25px;
}

.modalFooter {
    margin-left: 40%;
    margin-right: 40%;
    position: relative;
    padding: 25px 25px 0 0;
}

.modalOverlay {
    position: fixed;
    inset: 0px;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 100;
}
@import '../../theme/variables';

.main {
    background-color: $accentColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 12%;

        .message {
            font-style: normal;
            font-weight: 400;
            text-align: center;
            color: #FFFFFF;
            font-size: 32px;
            width: 100%;
        }

        .sign {
            border-radius: 50%;
            width: 216px;
            height: 216px;
            background: #332B78;
            display: flex;
            align-items: center;
            justify-content: center;

            .text {
                font-style: normal;
                font-weight: 700;
                font-size: 110px;
                color: #50E2D0;
            }
        }
    }

    .solarisLogo {
        height: 80px;
        width: 150px;
        position: absolute;
        right: 10px;
        bottom: 0;
    }
}

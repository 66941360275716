//base component: https://github.com/springload/react-accessible-accordion#readme
@import '../../theme/_variables.scss';

.accordionItem {
    padding-top: 15px;

    .accordionButton {
        background: $secondaryColor;
        cursor: pointer;
        border: none;
        color: #FFFFFF;
        font-weight: 400;
        font-size: 20px;
        height: 50px;
        padding: 20px;

        &:before {
            display: inline-block;
            content: '';
            height: 10px;
            width: 10px;
            margin-right: 12px;
            border-bottom: 2px solid currentColor;
            border-right: 2px solid currentColor;
            transform: rotate(-45deg);
        }

        .itemButtonContent {
            display: inline-flex;
            position: relative;
            padding: 10px;
            width: 97%;

            .left {
                width: 100%;
            }

            .trash {
                content: url('../../assets/img/trash.png');
                position: relative;

                height: 30px;
                width: 30px;
            }
        }
    }

    .accordionButton[aria-expanded='true'],
    .accordionButton[aria-selected='true'] {
        &:before {
            transform: rotate(45deg);
        }
    }

    [hidden] {
        display: none;
    }

    .accordionPanel {
        padding: 20px 20px 20px 30px;
        background-color: #E6E6E6;
    }
}

@media screen and (max-height: 892px),
screen and (max-width: 1250px) {
    .accordionItem {
        .accordionButton {
            .itemButtonContent {
                width: 94%;
                padding: 7px;
            }
        }
    }
}